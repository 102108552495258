import React from "react"

const Video = ({ url, title, className='' }) => {
  return (
    <div 
      className={"video " + className}
    >
      <iframe
        src={url + '?controls=0'}
        title={title}
        width="100%"
        height="100%"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  )

}

export default Video