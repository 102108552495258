import React from "react"
import PropTypes from "prop-types"
import Packs from "./Packs"
import Image from "./core/Image"
import Icon1 from "../assets/images/icon-sheets.svg"
import Icon2 from "../assets/images/icon-tree.svg"
import Icon3 from "../assets/images/icon-bin.svg"

const icons = [Icon1, Icon2, Icon3]

const Tout2 = ({
  title,
  subtitle,
  text,
  image,
  list,
  ctaLink,
  ctaText,
  className
}) => {
  return (
    <section
      className={`container tout2 ${className}`}
    >
      <div
        className="tout2__image"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        <Image
          className="tout2__img"
          data={image}
          alt="hero image"
          objectFit="contain"
        />
        <Packs />
      </div>

      <div
        className="tout2__copy"
        data-sal="fade"
        data-sal-delay="500"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        <h2 className="tout2__title">{title}</h2>
        <h3 className="tout2__subtitle">{subtitle}</h3>
        <p className="tout2__text">{text}</p>
        <ul className="tout2__list">
          {list.map((item, index) => (
            <li key={index} className="tout2__list-item">
              <img src={icons[index]} alt="icon" className="tout2__list-icon" />
              <span className="tout2__list-text">{item}</span>
            </li>
          ))}
        </ul>
        <a 
          className="tout2__cta button button-primary" 
          href={ctaLink}
          target="_blank"
        >
          {ctaText}
        </a>
      </div>
    </section>
  )
}

Tout2.defaultProps = {
  className: "tout2",
}

Tout2.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.node,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  image: PropTypes.object,
  className: PropTypes.string.isRequired
}

export default Tout2
