import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import VideoBlock from "../components/VideoBlock"
import Tout1 from "../components/Tout1"
import Tout2 from "../components/Tout2"

const Homepage = () => {

  const data = useStaticQuery(graphql`
    {
      page: mdx(fileAbsolutePath: { regex: "/content/index/" }) {
        body
        frontmatter {
          path
          title
          hero {
            background
            image
            title
          }
          videoBlock {
            images
            urls
          }
          tout1 {
            title
            ctas {
              link
              text
            }
            image
          }
          tout2 {
            title
            subtitle
            text
            image
            list
            ctaLink
            ctaText
          }
        }
      }
      images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "assets/images"}}) {
        edges {
          node {
            childImageSharp {
              id
              fluid {
                originalName
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const {
    hero,
    videoBlock,
    tout2,
    tout1
  } = data.page.frontmatter

  const images = data.images.edges.map(data => data.node.childImageSharp.fluid)
  const getImage = name => images.find(({ originalName }) => originalName === name)

  return (
    <>
      <SEO title="Home" />

      <Hero
        title={hero.title}
        image={getImage(hero.image)}
        background={getImage(hero.background)}
      />

      <VideoBlock
        images={videoBlock.images.map(getImage)}
        urls={videoBlock.urls}
      />

      <Tout2
        {...tout2}
        image={getImage(tout2.image)}
      />


      <Tout1
        {...tout1}
        image={getImage(tout1.image)}
      />
    </>
  )
}

export default Homepage