import React from "react"
import Icon1 from "../assets/images/icon-1pack.svg"
import Icon2 from "../assets/images/icon-2pack.svg"
import Icon3 from "../assets/images/icon-3pack.svg"

const icons = [Icon1, Icon2, Icon3]

const Packs = ({ }) => {
    return (
        <div className="packs">
            <h3 className="packs__title">Available in:</h3>
            <ul className="packs__list">
                {icons.map((icon, i) => (
                    <li key={i} className="packs__item">
                        <img src={icon} alt="icon" className={'packs__icon packs__icon--' + i} />
                        <span className="packs__text">{i + 1} Pack</span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Packs
