import React, { useState } from "react"
import PropTypes from "prop-types"
import Video from "./core/Video"
import Background from "../assets/images/wave.svg"
import Gradient from "../assets/images/gradient.svg"
import Gradient2 from "../assets/images/gradient2.svg"
import CampaignLogo from "../assets/images/campaign-logo.svg"

export default function VideoBlock({ urls }) {
  const [videoIndex, setVideoIndex] = useState(0)
  const [isHovering, setIsHovering] = useState(false)
  const [videoKeys, setVideoKeys] = useState([0,1,2].map(i => Math.floor(Math.random() * 1000000)))

  function reloadVideo(i) {
    const newVideoKeys = [...videoKeys]
    newVideoKeys[i] = Math.floor(Math.random() * 1000000)
    setVideoKeys(newVideoKeys)
  }
  
  function handleChangeVideo(i) {
    reloadVideo(videoIndex)
    setVideoIndex(i)
  }
  return (
    <>
      <section className="video-block">
        <img src={Background} alt="background" className="video-block__background" />
        <div className="video-block__content container">
          <img src={CampaignLogo} alt="logo" className="video-block__logo" />
          <div className="video-block__videos">
            {urls.map((url, i) => (
              <Video
                key={videoKeys[i]}
                url={url}
                title="video"
                className={'video-block__video ' + (i === videoIndex ? 'video-block__video--active' : '')} 
              />
            ))}
          </div>
          <div className="video-block__buttons">
            {urls.map((url, i) => (
              <button
                key={i}
                className={'video-block__button ' + (i === videoIndex && !isHovering ? 'video-block__button--active' : '')}
                onClick={() => handleChangeVideo(i)}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              />
            ))}
          </div>
        </div>
        <img src={Gradient} alt="logo" className="video-block__gradient" />
        <div className="video-block__gradient2" >
          <img src={Gradient2} alt="gradient" />
        </div>
      </section>
    </>
  )
}

VideoBlock.propTypes = {
urls: PropTypes.array,
images: PropTypes.array,
}
