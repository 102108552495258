import React from "react"
import PropTypes from "prop-types"
import Button from "./core/Button"
import Image from "./core/Image"
import Background from "../assets/images/wave2.svg"

const Tout1 = ({
  title,
  text,
  ctas,
  image,
  className
}) => {
  return (
    <section
      className={`container tout1 ${className}`}
    >
      <img src={Background} alt="background" className="tout1__background" />
      <div
        className="tout1__copy"
        data-sal="fade"
        data-sal-duration="1500"
        data-sal-easing="ease"
      >
        <div className="tout1__copy__inner">
          <h2 className="tout1__title" dangerouslySetInnerHTML={{ __html: title }} />
          {text && <p className="tout1__text">{text}</p>}
          {ctas && ctas.map(({ link, text }, i) => (
            <a
              key={i}
              className={"button button-primary tout1__button tout1__button--" + (i + 1)}
              href={link}
              target="_blank"
            >{text}</a>
          ))}
        </div>
      </div>
      <div
        className="tout1__images"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease-out"
      >
        <Image className="tout1__image" data={image} alt="" />
      </div>
    </section>
  )
}

Tout1.defaultProps = {
  className: "tout",
  title: "",
  text: "",
}

Tout1.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.node,
  ctas: PropTypes.array,
  image: PropTypes.object,
}

export default Tout1
