import { Link } from "gatsby"
import React from "react"

const Button = ({ colour, text, link, type, onClick, gatsbyLink }) => {

  const className = `button button-${colour}`
  
  return type === "link" ? ( 
    ( gatsbyLink ?
      <Link
        data-text={text}
        className={className}
        to={link}
        onClick={() => onClick}
      >
        {text}
      </Link> :
      <a className={className} data-text={text} href={link}>{text}</a> 
    )
  ) : (
    <button onClick={onClick} data-text={text} className={className}>{text}</button>
  )
}

export default Button
