import PropTypes from "prop-types"
import React from "react"
import Image from "./core/Image"

export default function Hero({ background, title, image }) {
  return (
    <>
      <section className="hero">
        <Image
          className="hero__background"
          data={background}
          alt="background image"
          objectFit="cover"
        />
        <div className="hero__content container">
          <h1 className="hero__title" dangerouslySetInnerHTML={{ __html: title }} />
          <Image
            className="hero__image"
            data={image}
            alt="hero image"
            objectFit="contain"
          />
        </div>
      </section>
    </>
  )
}

Hero.propTypes = {
  image: PropTypes.object.isRequired,
}
